<template>
  <!-- 创建学习圈 -->
  <a-modal
    :title="$t('bbs.create_circles')"
    width="100%"
    wrapClassName="full-modal"
    :keyboard="false"
    :closable="false"
    v-model:visible="visible"
    @cancel="handleCancel"
  >
    <template #footer>
      <div class="custom-footer">
        <div></div>
        <div class="btns">
          <a-button @click="handleCancel">{{ $t('CM_Cancel') }}</a-button>
          <!-- 取消 -->
          <a-button type="primary" :loading="loading" @click="handleOk"
            >{{ $t('CM_Confirm') }}</a-button
          >
          <!-- 确定 -->
        </div>
      </div>
    </template>
    <div class="full-modal-close" @click="handleCancel">
      <CloseOutlined class="full-modal-close-x" />
    </div>
    <div class="full-modal-content">
      <div class="post-form">
        <a-form
          class="edit-form-wrap"
          :model="formData"
          layout="vertical"
          ref="formRef"
        >
          <!-- 名称 -->
          <a-form-item
            class="resize-none"
            :label="$t('bbs.group.name')"
            name="groupName"
            :rules="{
              required: true,
              message: $t('bbs.group.please_enter_name'),
              trigger: 'change',
            }"
          >
            <!-- 请输入名称 -->
            <a-textarea
              :rows="1"
              v-model:value="formData.groupName"
              :placeholder="$t('bbs.group.please_enter_name')"
              showCount
              :maxlength="20"
            />
          </a-form-item>
          <!-- 封面 -->
          <a-form-item :label="$t('bbs.group.cover')" name="cover">
            <p class="tips">
              {{ $t('bbs.group.cover_tip') }}
              <!-- 建议尺寸130*130，文件不超过1MB的jpg、jpeg、png图片 -->
            </p>
            <div class="covers">
              <div
                class="covers-item add-ed"
                v-if="upCover"
                @click="formData.coverImg = upCover"
              >
                <img :src="upCdnCover" alt="cover" />
                <div class="covers-item-close" @click.stop="delCover">
                  <CloseOutlined class="icon" />
                </div>
                <div
                  class="covers-item-checked"
                  v-if="formData.coverImg === upCover"
                >
                  <CheckOutlined class="icon" />
                </div>
              </div>
              <div class="covers-item add" v-else>
                <PlusOutlined style="color: #ddd; font-size: 40px" />
                <input
                  class="up-input"
                  ref="uploadInput"
                  type="file"
                  accept="image/jpg, image/jpeg, image/png"
                  @change="selectFile"
                />
              </div>
              <div
                class="covers-item"
                v-for="item in coverList"
                :key="item.coverId"
                @click="formData.coverImg = item.coverImg"
              >
                <div
                  class="covers-item-checked"
                  v-if="formData.coverImg === item.coverImg"
                >
                  <CheckOutlined class="icon" />
                </div>
                <img :src="item.coverImg" alt="cover" />
              </div>
            </div>
          </a-form-item>
          <!-- 简介 -->
          <a-form-item :label="$t('bbs.group.intro')" name="intro">
            <a-textarea
              :rows="4"
              v-model:value="formData.intro"
              :placeholder="$t('bbs.group.intro_p')"
              showCount
              :maxlength="100"
            />
            <!-- 请输入简介 -->
          </a-form-item>
          <!-- 圈主简介 -->
          <a-form-item :label="$t('bbs.group.owner')" name="masterIntro">
            <a-textarea
              :rows="4"
              v-model:value="formData.masterIntro"
              :placeholder="$t('bbs.group.owner_p')"
              showCount
              :maxlength="100"
            />
            <!-- 请输入圈主简介 -->
          </a-form-item>
          <!-- 访问权限 -->
          <a-form-item :label="$t('bbs.group.access_permission')" name="scope1" @change="scope1Change">
            <a-radio-group name="scope1" v-model:value="formData.scope1">
              <a-radio :value="8">{{ $t('bbs.group.public_circle') }}</a-radio>
              <!-- 公开圈子 -->
              <a-radio :value="6">{{ $t('bbs.group.private_circle') }}</a-radio>
              <!-- 私密圈子 -->
            </a-radio-group>
          </a-form-item>
          <!-- 加入限制 -->
          <a-form-item :label="$t('bbs.group.join_limit')" name="isJoin">
            <a-radio-group name="isJoin" v-model:value="formData.isJoin">
              <a-radio :value="1" :disabled="formData.scope1 === 6">
                {{ $t('bbs.group.no_need_review_tip') }} - {{ $t('bbs.group.no_need_review') }}
                <!-- 所有用户允许加入-无需审核 -->
              </a-radio>
              <a-radio :value="2" :disabled="formData.scope1 === 6">
                {{ $t('bbs.group.no_need_review_tip') }} - {{ $t('bbs.group.need_review') }}
                <!-- 所有用户允许加入-需审核 -->
              </a-radio>
              <a-radio :value="3">{{ $t('bbs.group.specify_user_to_join') }} - {{ $t('bbs.group.specify_user_to_join_tip_2') }}</a-radio>
              <!-- 指定用户加入-其他用户无法申请加入 -->
            </a-radio-group>
          </a-form-item>
          <!-- 浏览设置 -->
          <a-form-item :label="$t('bbs.group.browse_settings')" name="isUnjoinView">
            <a-checkbox v-model:checked="formData.isUnjoinView">
              {{ $t('bbs.group.allow') }}
              <!-- 允许未加入用户预览部分主题 -->
            </a-checkbox>
            <a-tooltip
              :title="$t('bbs.group.allow_tip')"
            >
            <!-- 勾选后，允许未加入的用户浏览三篇精华或最新发布的主题 -->
              <ExclamationCircleOutlined style="color: #999" />
            </a-tooltip>
          </a-form-item>
          <!-- 成员信息 -->
          <a-form-item :label="$t('bbs.group.member_info')" name="showMember">
            <a-checkbox v-model:checked="formData.showMember"
              >{{ $t('bbs.group.open_member_list') }}</a-checkbox
            >
            <!-- 开放成员列表 -->
            <a-tooltip :title="$t('bbs.group.open_member_list_tip')">
              <!-- 勾选后，在圈子主页展示成员列表 -->
              <ExclamationCircleOutlined style="color: #999" />
            </a-tooltip>
          </a-form-item>
          <!-- 发布主题权限 -->
          <a-form-item :label="$t('bbs.group.post_subject_permission')" name="isPublish">
            <a-radio-group name="isPublish" v-model:value="formData.isPublish">
              <a-radio :value="1">{{ $t('bbs.group.all_people') }}</a-radio>
              <!-- 所有人 -->
              <a-radio :value="2">{{ $t('bbs.group.circle_owner_only') }}</a-radio>
              <!-- 仅圈主 -->
              <a-radio :value="3">{{ $t('bbs.group.owner_admin') }}</a-radio>
              <!-- 圈主+管理员 -->
            </a-radio-group>
          </a-form-item>
          <!-- 评论回答回复权限 -->
          <a-form-item :label="$t('bbs.group.comment_reply_permission')" name="isComment">
            <a-radio-group name="isComment" v-model:value="formData.isComment">
              <a-radio :value="1">{{ $t('bbs.group.all_people') }}</a-radio>
              <!-- 所有人 -->
              <a-radio :value="2">{{ $t('bbs.group.circle_owner_only') }}</a-radio>
              <!-- 仅圈主 -->
              <a-radio :value="3">{{ $t('bbs.group.owner_admin') }}</a-radio>
              <!-- 圈主+管理员 -->
            </a-radio-group>
          </a-form-item>
          <!-- 匿名设置 -->
          <a-form-item :label="$t('bbs.group.anonymous_settings')">
            <a-checkbox v-model:checked="formData.isPublishAnonymous">
              {{ $t('bbs.group.allow_anonymous_post_subject') }}
              <!-- 允许匿名发布主题 -->
            </a-checkbox>
            <a-checkbox v-model:checked="formData.isCommentAnonymous">
              {{ $t('bbs.group.allow_anonymous_all') }}
              <!-- 允许匿名评论/回答/回复 -->
            </a-checkbox>
          </a-form-item>
        </a-form>
      </div>
    </div>
  </a-modal>
  <cropper
    :width="260"
    :height="260"
    ref="cropperRef"
    @cropper:url="upImgEnd"
  />
</template>

<script>
import { useI18n } from 'vue-i18n';
import { getCurrentInstance, reactive, toRefs, ref, createVNode } from "vue";

import { getCdnUrl } from "@/utils/tools";

import { postGroup } from "@/api/bbs";

import { Modal } from "ant-design-vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";

import cropper from "@/components/cropper.vue";

export default {
  name: "modalGroup",
  components: {
    cropper,
  },
  props: {
    coverList: {
      type: Array,
      required: true,
    },
  },
  emits: ["handleOk"],
  setup(props, { emit }) {
    const { t: $t } = useI18n();
    const { proxy } = getCurrentInstance();
    const state = reactive({
      visible: false,
      loading: false,
      upCover: "",
      upCdnCover: "",
      formData: {
        groupName: "",
        coverImg: "",
        intro: "",
        masterIntro: "",
        scope1: 8,
        isJoin: 1,
        isPublish: 1,
        isComment: 1,
        isUnjoinView: false,
        showMember: false,
        isPublishAnonymous: true,
        isCommentAnonymous: true,
      },
    });

    const uploadInput = ref(null);
    const cropperRef = ref(null);
    const selectFile = (e) => {
      const target = e.target;
      const { files } = target;
      if (!files) return;
      const file = files[0];
      if (file.size / 1024 / 1024 > 1) {
        proxy.$message.warn($t('upload.max_size_tip', ['1M']));
        // 文件大小不能大于1M
        uploadInput.value.value = "";
        return;
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cropperRef.value.showDialog(String(reader.result));
        if (!uploadInput.value) return;
        uploadInput.value.value = "";
      };
    };

    const upImgEnd = ({ exist, file }) => {
      state.upCover = file;
      state.upCdnCover = exist
        ? process.env.VUE_APP_RES_URL + file
        : getCdnUrl(file, "temp");
      state.formData.coverImg = file;
    };

    const delCover = () => {
      state.upCover = "";
      state.upCdnCover = "";
      state.formData.coverImg = props.coverList[0].coverImg;
    };

    const scope1Change = () => {
      if (state.formData.scope1 === 6) {
        state.formData.isJoin = 3;
      }
    };

    const openModal = (groupDeatil) => {
      state.visible = true;
      state.upCover = "";
      state.upCdnCover = "";
      if (groupDeatil) {
        let data = JSON.parse(JSON.stringify(groupDeatil));
        state.formData = data;
        state.formData.isUnjoinView = data.isUnjoinView === 1;
        state.formData.showMember = data.showMember === 1;
        state.formData.isPublishAnonymous = data.isPublishAnonymous === 1;
        state.formData.isCommentAnonymous = data.isCommentAnonymous === 1;
        if (data.coverImg.indexOf("default") === -1) {
          state.upCover = data.coverImg;
          state.upCdnCover = data.coverImg;
        }
      } else {
        state.formData = {
          groupName: "",
          coverImg: props.coverList[0].coverImg,
          intro: "",
          masterIntro: "",
          scope1: 8,
          isJoin: 1,
          isPublish: 1,
          isComment: 1,
          isUnjoinView: false,
          showMember: false,
          isPublishAnonymous: true,
          isCommentAnonymous: true,
        };
      }
    };

    const formRef = ref(null);
    const handleOk = () => {
      formRef.value.validate().then(() => {
        state.loading = true;
        let formData = JSON.parse(JSON.stringify(state.formData));
        formData.isUnjoinView = formData.isUnjoinView ? 1 : 2;
        formData.showMember = formData.showMember ? 1 : 2;
        formData.isPublishAnonymous = formData.isPublishAnonymous ? 1 : 2;
        formData.isCommentAnonymous = formData.isCommentAnonymous ? 1 : 2;
        postGroup(formData).then((res) => {
          state.loading = false;
          if (res.ret == 0) {
            if (formData.groupId) {
              proxy.$message.success($t('CM_Success'));
              // 操作成功！
              emit("handleOk", res.data);
              state.visible = false;
            } else {
              proxy.$message.success($t('bbs.group.created_successfully'));
              // 创建圈子成功，跳转中...
              state.loading = true;
              setTimeout(() => {
                emit("handleOk", res.data);
              }, 1000);
            }
          }
        });
      });
    };

    const handleCancel = () => {
      Modal.confirm({
        title: () => $t('bbs.confirm_cancel'),
        // 确认取消
        icon: () => createVNode(ExclamationCircleOutlined),
        content: $t('bbs.confirm_cancel_tip'),
        // 当前操作尚未保存，是否确认取消？
        onOk() {
          state.visible = false;
        },
      });
    };

    return {
      ...toRefs(state),
      uploadInput,
      cropperRef,
      selectFile,
      upImgEnd,
      delCover,
      scope1Change,
      openModal,
      formRef,
      handleOk,
      handleCancel,
    };
  },
};
</script>

<style lang="less" scoped>
.custom-footer {
  .mixinWrap(880px);
  .mixinFlex(space-between; center);
}
.post-form {
  .mixinWrap(880px);
  .resize-none {
    ::v-deep(textarea) {
      resize: none;
    }
  }
  .tips {
    font-size: 14px;
    line-height: 22px;
    color: #c4c4c4;
    margin-bottom: 8px;
  }
  .covers {
    font-size: 0;
    &-item {
      width: 100px;
      height: 100px;
      margin-right: 24px;
      .mixinImgWrap(100px; 100px);
      border-radius: 4px;
      display: inline-flex;
      position: relative;
      cursor: pointer;
      &.add {
        border: 2px dashed #ddd;
        .mixinFlex(center; center);
        display: inline-flex;
        vertical-align: text-bottom;
        position: relative;
        .up-input {
          position: absolute;
          top: 0;
          left: 0;
          width: 100px;
          height: 100px;
          opacity: 0;
          background-color: transparent;
          color: transparent;
          cursor: pointer;
        }
      }
      &-checked {
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        bottom: -20px;
        right: -20px;
        width: 40px;
        height: 40px;
        transform: rotate(45deg);
        .icon {
          color: #fff;
          font-size: 12px;
          position: absolute;
          bottom: 14px;
          right: 26px;
          transform: rotate(-45deg);
        }
      }
      &-close {
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 0;
        right: 0;
        width: 20px;
        height: 20px;
        border-radius: 0 0 0 4px;
        .mixinFlex(center; center);
        .icon {
          color: #fff;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
